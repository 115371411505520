/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/theming";

@include mat-core();

$critertec-primary: mat-palette($mat-indigo);
$critertec-accent: mat-palette($mat-indigo);
$critertec-warn: mat-palette($mat-red);
$critertec-success: mat-palette($mat-green);

$critertec-theme: mat-light-theme($critertec-primary, $critertec-accent, $critertec-warn);
@include angular-material-theme($critertec-theme);

@font-face {
  font-family: "Segoe UI Black";
  src: local("Segoe UI Black"), url(./assets/fonts/segoeuib.ttf) format("opentype");
}

@font-face {
  font-family: "Segoe UI Regular";
  src: local("Segoe UI Regular"), url(./assets/fonts/segoeui.ttf) format("opentype");
}

@font-face {
  font-family: "Segoe UI Italic";
  src: local("Segoe UI Italic"), url(./assets/fonts/segoeuii.ttf) format("opentype");
}

:root {
  --critertec-primary: #091F47;
  --critertec-accent: #01B6A9;
  --critertec-warn: #{mat-color($critertec-warn)};
  --critertec-success: #{mat-color($critertec-success)};
}
